import {
  LOADING_REPORT_DATA,
  LOADING_REPORT_DATA_SUCCESS,
  LOADING_REPORT_DATA_FAILURE,
  CREATING_REPORT_DATA,
  CREATING_REPORT_DATA_SUCCESS,
  CREATING_REPORT_DATA_FAILURE,
  SAVING_REPORT_DATA,
  SAVING_REPORT_DATA_SUCCESS,
  SAVING_REPORT_DATA_FAILURE,
  REFRESHING_REPORT_DATA,
  REFRESHING_REPORT_DATA_SUCCESS,
  REFRESHING_REPORT_DATA_FAILURE,
  WRITING_REPORT_COMMENT,
  LOADING_REPORT_ARCHIVED_DATA,
  LOADING_REPORT_ARCHIVED_DATA_SUCCESS,
  LOADING_REPORT_ARCHIVED_DATA_FAILURE,
} from './actions';

const initial = { reportData: {}, isLoading: false, lastRequestData: {} };

const sortData = (receivedReportData) => {
  receivedReportData.sections.sort((a, b) => ((a.sortNumber > b.sortNumber) ? 1 : -1));
  receivedReportData.sections.forEach(section => section.elements.sort((a, b) => ((a.sortNumber > b.sortNumber) ? 1 : -1)));
  return receivedReportData;
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_REPORT_DATA: {
      return { ...state, isLoading: true, lastRequestData: action.requestData };
    }
    case LOADING_REPORT_DATA_SUCCESS: {
      return state.lastRequestData === action.requestData ?
        { ...state, reportData: action.reportData && action.reportData.length > 0 ? sortData(action.reportData[0]) : undefined, isLoading: false } :
        // ignores the received data if it does not correspond to the last request
        { ...state };
    }
    case LOADING_REPORT_DATA_FAILURE: {
      return { ...state, error: action.error, isLoading: false };
    }
    case LOADING_REPORT_ARCHIVED_DATA: {
      return { ...state, isLoadingArchived: true };
    }
    case LOADING_REPORT_ARCHIVED_DATA_SUCCESS: {
      return (
        {
          ...state,
          archivedReportData: action.reportData ? action.reportData.map(d => ({ ...d, sections: undefined })) : undefined,
          isLoadingArchived: false,
        });
    }
    case LOADING_REPORT_ARCHIVED_DATA_FAILURE: {
      return { ...state, error: action.error, isLoadingArchived: false };
    }
    case CREATING_REPORT_DATA: {
      return { ...state, isLoading: true, lastRequestData: action.requestData };
    }
    case CREATING_REPORT_DATA_SUCCESS: {
      return state.lastRequestData === action.requestData ?
        { ...state, reportData: action.reportData, isLoading: false } :
        { ...state };
    }
    case CREATING_REPORT_DATA_FAILURE: {
      return { ...state, error: action.error, isLoading: false };
    }
    case SAVING_REPORT_DATA: {
      return { ...state, isLoading: true };
    }
    case SAVING_REPORT_DATA_SUCCESS: {
      return { ...state, reportData: { ...state.reportData, headerId: action.headerId }, isLoading: false };
    }
    case SAVING_REPORT_DATA_FAILURE: {
      return { ...state, error: action.error, isLoading: false };
    }
    case REFRESHING_REPORT_DATA: {
      return { ...state, isLoading: true };
    }
    case REFRESHING_REPORT_DATA_SUCCESS: {
      return { ...state, reportData: action.reportData, isLoading: false };
    }
    case REFRESHING_REPORT_DATA_FAILURE: {
      return { ...state, error: action.error, isLoading: false };
    }
    case WRITING_REPORT_COMMENT: {
      const { sectionType, elementType, id, comment } = action;
      const updatedReportDataSections = state.reportData.sections.map(section => (
        section.sectionType === sectionType ?
          {
            ...section,
            elements: (section.elements.map(el => (el.elementType === elementType ?
              {
                ...el,
                data: el.data.map(tfv => (tfv.tfvId === id ?
                  {
                    ...tfv,
                    comment,
                  }
                  : tfv)),
              }
              : el))
            ),
          }
          : section
      ));
      return { ...state, reportData: { ...state.reportData, sections: updatedReportDataSections } };
    }
    default: {
      return state;
    }
  }
};
