import { PrimaryButton } from '@atfm/atfm-material-ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSelectedCTA,
} from '../../store/sectors/actions';
import {
  selectConfigsData,
  selectSelectedCTA,
} from '../../store/sectors/selectors';
import './MultiCTAHandle.css';

function MultiCTAHandle() {
  const dispatch = useDispatch();

  const sectoConfs = useSelector(selectConfigsData);
  const selectedCTA = useSelector(selectSelectedCTA);

  const handleSelectCTA = (profileName) => {
    dispatch(updateSelectedCTA(profileName));
  };

  return (
    <div className="multi-cta-handle-container">
      {sectoConfs.map((item, index) => (
        <PrimaryButton
          key={`${index}-${item.name}`}
          size="medium"
          id="multi-cta-handle-button"
          className={`multi-cta-handle-button ${selectedCTA.name === item.name ? 'active' : 'inactive'}`}
          onClick={() => handleSelectCTA(item.name)}
        >
          {item.name}
        </PrimaryButton>
      ))}
    </div>
  );
}

export default MultiCTAHandle;
