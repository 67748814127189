import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './AutoComplete.css';

export default class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearable: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
    };
  }

  handleChange = (option) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: option.value,
      },
    });
  };

  render() {
    const {
      isClearable,
      isSearchable,
      isLoading,
      isRtl,
    } = this.state;
    const lookValue = this.props.value ? this.props.options.filter(o => o.value === this.props.value) : null;
    const defaultValue = lookValue && lookValue.length > 0 ? lookValue[0] : null;

    return (
      <Select
        className={this.props.className}
        classNamePrefix={this.props.classNamePrefix}
        isDisabled={this.props.disabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={this.props.name}
        value={defaultValue}
        placeholder={this.props.placeholder}
        options={this.props.options}
        onChange={this.handleChange}
      />
    );
  }
}

AutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    value: PropTypes.string,
  })).isRequired,
};

AutoComplete.defaultProps = {
  name: 'AutoComplete',
  className: 'autocomplete',
  classNamePrefix: 'autocomplete',
  disabled: false,
  placeholder: '...',
};
