import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './InsertNewConfig.css';

function InsertNewConfig({ listId, entryId, insertConfig }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className="hover-area" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>

      { isHovering &&
        <div className="insertConfiguration">
          <div className="line" />
          <button className="insertConfigurationButton" onClick={() => insertConfig(listId, entryId)} type="button">
            <span>Insert Configuration</span>
          </button>
          <div className="line" />
        </div>}
    </div>
  );
}
InsertNewConfig.propTypes = {
  listId: PropTypes.number.isRequired,
  entryId: PropTypes.number.isRequired,
  insertConfig: PropTypes.func.isRequired,
};

export default InsertNewConfig;
