import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const useUserDefaultContext = () => {
  const [userInfo, setUserInfo] = useState(undefined);
  const [tfvName, setTfvName] = useState(userInfo ? userInfo.tfv : undefined);
  const [sectoName, setSectoName] = useState(userInfo ? userInfo.secto : undefined);

  return {
    userInfo,
    setUserInfo,
    tfvName,
    setTfvName,
    sectoName,
    setSectoName,
  };
};

export function UserProvider({ children }) {
  const context = useUserDefaultContext();
  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default UserContext;
export const useUserContext = () => useContext(UserContext);
