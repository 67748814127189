/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  getWhatif,
  deleteWhatif,
  createWhatif,
  createCopyWhatif,
  addMeasure,
  deleteMeasure,
  updateMeasure,
  proposeStam,
  getFullDaySimulationsList,
  getComputeNewTrajectory,
} from './actions';
import { WhatifMeasures, HotspotStatus } from '../../constants';

const initialState = {
  // The current selected whatif
  selectedWhatif: undefined,
  // Holds the current selected whatif that is used with the flight list, hotspot details and STAM panel
  activeWhatif: undefined,
  // Holds the measures of selectedWhatif that have been merged and transformed accordingly to work with the app
  activeWhatifMeasures: [],
  // Holds all whatifs that are currently open
  whatifsOpen: [],
  // Holds the simulations list
  simulationsList: [],
  isProposingStam: false,
  requestingWhatIf: false,
  requestingSimList: false,
  isRequestingGdpSlotList: false,
  requestingComputeTrajectory: false,
  computeTrajectory: {},
  computeTrajectoryFails: false,
};

const handleCreatedWhatif = (state, { payload }) => {
  const { sessionId } = payload;
  const whatifsOpen = state.whatifsOpen.find(whatif => whatif.sessionId === sessionId) ?
    state.whatifsOpen.map(whatif => (whatif.sessionId === sessionId ? payload : whatif)) :
    state.whatifsOpen.concat(payload);
  return {
    ...state,
    selectedWhatif: payload,
    activeWhatifMeasures: payload.measures,
    whatifsOpen,
    requestingWhatIf: false,
  };
};

const whatifSlice = createSlice({
  name: 'whatif',
  initialState,
  reducers: {
    clearLoadedWhatif: (state) => {
      state.selectedWhatif = undefined;
      state.activeWhatifMeasures = [];
    },
    addLocalGdpMeasure: (state, { payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.concat({
        id: uuidv4(),
        tfvId: payload?.tfvId,
        from: payload?.start,
        to: payload?.end,
        type: WhatifMeasures.GDP,
        localMeasure: true,
        isEditing: true,
        isLoading: false,
      });
    },
    addLocalMeasure: (state, { payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.concat({
        id: uuidv4(),
        gufi: payload.gufi,
        callsign: payload.callsign,
        eobt: payload.eobt,
        type: WhatifMeasures.TONB,
        localMeasure: true,
        isEditing: true,
        isLoading: false,
      });
    },
    updateLocalMeasure: (state, { payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.map(measure => (measure.id === payload.id ?
        { ...measure, ...payload } : measure));
    },
    deleteLocalMeasure: (state, { payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.filter(measure => measure.id !== payload.measureId);
    },
    changeEditing: (state, { payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.map(measure => (measure.id === payload.measureId ?
        { ...measure, isEditing: payload.isEditing } : measure));
    },
    clearComputeTrajectory: (state) => {
      state.computeTrajectory = {};
    },
    updateComputeTrajectory: (state, { payload }) => {
      state.computeTrajectory = { ...payload };
    },
    activateSimulation: (state, { payload }) => {
      state.activeWhatif = payload;
    },
    deactivateSimulation: (state) => {
      state.activeWhatif = undefined;
    },
  },
  extraReducers: {
    [getWhatif.pending]: (state) => {
      state.requestingWhatIf = false;
    },
    [getWhatif.fulfilled]: (state, { payload }) => {
      const { measures } = payload;

      const whatifsOpen = state.whatifsOpen.find(whatif => whatif.sessionId === payload.sessionId) ?
        state.whatifsOpen.map(whatif => (whatif.sessionId === payload.sessionId ? payload : whatif)) :
        state.whatifsOpen.concat(payload);
      if (payload.measures) {
        // Transforming received measures
        const measuresReceived = payload.measures.map(measure => ({
          ...measure, isEditing: false, isLoading: false,
        }));
        // If it's a different whatif simply return the measures received
        if (state.selectedWhatif && state.selectedWhatif.sessionId !== payload.sessionId) {
          return { ...state, selectedWhatif: payload, activeWhatifMeasures: measuresReceived };
        }
        // Gathering current and new measures ids
        const receivedMeasuredIds = measuresReceived.map(measure => measure.id);
        const currentMeasuresIds = state.activeWhatifMeasures.map(measure => measure.id);
        // Filtering removed measures
        const newMeasures = state.activeWhatifMeasures
          .filter(measure => measure.localMeasure || measure.isEditing || receivedMeasuredIds.includes(measure.id));
        // Updating existing measures instead of concating for keeping order
        const updatedMeasures = newMeasures.map(measure => (measure.localMeasure || measure.isEditing ? measure :
          { ...measure, ...measuresReceived[receivedMeasuredIds.indexOf(measure.id)] }));
        // Adding new measures received
        const activeWhatifMeasures = updatedMeasures.concat(measuresReceived.filter(measure => !currentMeasuresIds.includes(measure.id)));
        return {
          ...state, selectedWhatif: payload, activeWhatifMeasures, whatifsOpen, requestingWhatIf: false,
        };
      }
      return {
        ...state,
        selectedWhatif: payload,
        activeWhatifMeasures: measures,
        whatifsOpen,
        requestingWhatIf: false,
      };
    },
    [getWhatif.rejected]: (state, { payload }) => {
      state.error = payload;
      state.requestingWhatIf = false;
    },
    [deleteWhatif.fulfilled]: (state, { payload }) => {
      const { sessionId } = payload;
      state.selectedWhatif = undefined;
      state.activeWhatifMeasures = [];
      state.whatifsOpen = state.whatifsOpen.filter(whatif => whatif.sessionId !== sessionId);
    },
    [deleteWhatif.rejected]: (state, { payload }) => {
      state.deleteWhatifError = payload;

      setTimeout(() => {
        state.deleteWhatifError = undefined;
      }, 5000);
    },
    [createWhatif.pending]: (state) => {
      state.requestingWhatIf = true;
    },
    [createWhatif.fulfilled]: handleCreatedWhatif,
    [createWhatif.rejected]: (state, { payload }) => {
      state.error = payload;
      state.requestingWhatIf = false;
    },
    [createCopyWhatif.pending]: (state) => {
      state.requestingWhatIf = true;
    },
    [createCopyWhatif.fulfilled]: handleCreatedWhatif,
    [createCopyWhatif.rejected]: (state, { payload }) => {
      state.error = payload;
      state.requestingWhatIf = false;
    },
    [addMeasure.pending]: (state, { meta }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.map(measure => (measure.id === meta.arg.measureId && measure.localMeasure ?
        { ...measure, isLoading: true } : measure));
    },
    [addMeasure.fulfilled]: (state, { meta, payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.map(measure => (measure.id === meta.arg.measureId && measure.localMeasure ?
        {
          ...payload, id: payload.id, type: payload.type.toUpperCase(), isEditing: payload.validationStatus !== 'OK', isLoading: false,
        } : measure));
      state.whatifsOpen = state.whatifsOpen.map(whatif => (whatif.sessionId === meta.arg.sessionId ?
        { ...whatif, measures: whatif.measures.concat(payload) } : whatif));
    },
    [addMeasure.rejected]: (state, { meta, payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.map(measure => (measure.id === meta.arg.measureId && measure.localMeasure ?
        { ...measure, isLoading: false } : measure));
      state.error = payload;
    },
    [deleteMeasure.fulfilled]: (state, { meta }) => ({
      ...state,
      activeWhatifMeasures: state.activeWhatifMeasures.filter(measure => measure.id !== meta.arg.measureId),
      whatifsOpen: state.whatifsOpen.map(whatif => ({ ...whatif, measures: whatif.measures.filter(measure => measure.id !== meta.arg.measureId) })),
    }),
    [deleteMeasure.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [updateMeasure.pending]: (state, { meta }) => {
      const updatedMeasures = state.activeWhatifMeasures.map(measure =>
        (measure.id === meta.arg.measureId ? { ...measure, isLoading: true } : measure));
      return { ...state, activeWhatifMeasures: updatedMeasures };
    },
    [updateMeasure.fulfilled]: (state, { meta, payload }) => {
      const updatedMeasures = state.activeWhatifMeasures.map(measure =>
        (measure.id === meta.arg.measureId ? {
          ...payload, isEditing: payload.validationStatus !== 'OK', isLoading: false,
        } : measure));
      const updatedWhatifsOpen = state.whatifsOpen.map(whatif =>
        ({ ...whatif, measures: whatif.measures.map(measure => (measure.id === meta.arg.measureId ? payload : measure)) }));
      return { ...state, activeWhatifMeasures: updatedMeasures, whatifsOpen: updatedWhatifsOpen };
    },
    [updateMeasure.rejected]: (state, { meta, payload }) => {
      state.activeWhatifMeasures = state.activeWhatifMeasures.map(measure =>
        (measure.id === meta.arg.measureId ? { ...measure, isLoading: false } : measure));
      state.error = payload;
    },
    [proposeStam.pending]: (state) => {
      state.isProposingStam = true;
    },
    [proposeStam.fulfilled]: (state) => {
      state.isProposingStam = false;
      state.selectedWhatif.status = HotspotStatus.STAM_ONGOING;
    },
    [proposeStam.rejected]: (state, { payload }) => {
      state.isProposingStam = false;
      state.error = payload;
      state.selectedWhatif.status = HotspotStatus.ACTIVE;
    },
    [getFullDaySimulationsList.pending]: state => ({ ...state, requestingSimList: true }),
    [getFullDaySimulationsList.fulfilled](state, { payload }) {
      const copiedSessions = payload.map(s => s.copyOf).filter(id => !!id);
      const filteredSessions = payload.filter(tmi => !copiedSessions.includes(tmi.sessionId));
      const sortedFiltredList = filteredSessions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

      return { ...state, requestingSimList: false, simulationsList: sortedFiltredList };
    },
    [getFullDaySimulationsList.rejected]: (state, { payload }) => {
      state.requestingSimList = false;
      state.error = payload;
    },
    [getComputeNewTrajectory.pending]: state => ({ ...state, requestingComputeTrajectory: true, computeTrajectoryFails: false }),
    [getComputeNewTrajectory.fulfilled]: (state, { payload }) => {
      const { gufi, callsign, cross, icaoAddress, icaoRoute } = payload;

      const impact = cross[0];

      return {
        ...state,
        requestingComputeTrajectory: false,
        computeTrajectoryFails: false,
        computeTrajectory: {
          gufi,
          callsign,
          deltaCo2: Math.round(impact.deltaCo2),
          delay: impact.delay,
          icaoAddress,
          icaoRoute,
        },
      };
    },
    [getComputeNewTrajectory.rejected]: (state, { payload }) => {
      state.requestingComputeTrajectory = false;
      state.computeTrajectoryFails = true;
      state.error = payload;
    },
  },
});

export const {
  clearLoadedWhatif,
  addLocalGdpMeasure,
  addLocalMeasure,
  updateLocalMeasure,
  deleteLocalMeasure,
  changeEditing,
  clearComputeTrajectory,
  updateComputeTrajectory,
  activateSimulation,
  deactivateSimulation,
} = whatifSlice.actions;
export default whatifSlice.reducer;
