import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import Routes from './routes';
import createStore from './store';
import './assets/styles/bootstrap.override.scss';
import './Common.scss';
import '@atfm/typeface';

const store = createStore();
const history = createBrowserHistory();

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} history={history}>
    <BrowserRouter history={history}>
      {Routes()}
    </BrowserRouter>
  </Provider>,
);
