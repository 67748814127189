import React, { useEffect, useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { StorageHelper } from '@atfm/utils';
import { TrafficPopupStatus, StorageKeys } from '../../constants';

const TrafficLoadContext = createContext();

const baseZindex = 980;

export const useTrafficLoadDefaultContext = () => {
  const [occupancyActive, setOccupancyActive] = useState(true);
  const [entryActive, setEntryActive] = useState(false);
  const [trafficLoadViewChanged, setTrafficLoadViewChanged] = useState(false);
  const [popupTfvList, setPopupTfvListState] = useState(StorageHelper.retrieveItem(StorageKeys.POPUP_TFVS) ?? []);
  const [popupTfvListChanged, setPopupTfvListChanged] = useState(false);
  const [popupTfvListPositions, setPopupTfvListPositionsState] = useState(StorageHelper.retrieveItem(StorageKeys.POPUP_TFVS_POSITIONS) ?? []);
  const [positionsUpdated, setPositionUpdated] = useState(false);

  useEffect(() => {
    setTrafficLoadViewChanged(true);
  }, [occupancyActive]); // we only need to listen to one of them change

  useEffect(() => {
    if (trafficLoadViewChanged) {
      setTrafficLoadViewChanged(false);
    }
  }, [trafficLoadViewChanged]);

  useEffect(() => {
    setPopupTfvListChanged(true);
  }, [popupTfvList]);

  useEffect(() => {
    if (popupTfvListChanged) {
      setPopupTfvListChanged(false);
    }
  }, [popupTfvListChanged]);

  useEffect(() => {
    setPositionUpdated(true);
  }, [popupTfvListPositions]);

  useEffect(() => {
    if (positionsUpdated) {
      setPositionUpdated(false);
    }
  }, [positionsUpdated]);

  const setPopupTfvList = (newValue) => {
    StorageHelper.storeItem(StorageKeys.POPUP_TFVS, newValue);
    setPopupTfvListState(newValue);
  };

  const setPopupTfvListPositions = (newValue) => {
    StorageHelper.storeItem(StorageKeys.POPUP_TFVS_POSITIONS, newValue);
    setPopupTfvListPositionsState(newValue);
  };

  const addPopupTfv = (tfvId, chartType, position) => {
    if (popupTfvList.some(t => t.tfvId === tfvId && t.chartType === chartType)) {
      return;
    }
    const status = TrafficPopupStatus.OPEN;
    const listToAdd = [...popupTfvList, { tfvId, chartType, status }];
    const positionListToAdd = [...popupTfvListPositions, { tfvId, chartType, position: { ...position, z: (baseZindex + popupTfvList.length + 1) } }];
    setPopupTfvList(listToAdd);
    setPopupTfvListPositions(positionListToAdd);
  };

  const removePopupTfv = (tfvId, chartType) => {
    const resultList = popupTfvList.filter(t =>
      !(tfvId === t.tfvId && chartType === t.chartType));
    const resultPositionList = popupTfvListPositions.filter(t =>
      !(tfvId === t.tfvId && chartType === t.chartType));
    setPopupTfvList(resultList);
    setPopupTfvListPositions(resultPositionList);
  };

  const collapsePopupTfv = (tfvId, chartType) => {
    const status = TrafficPopupStatus.COLLAPSED;
    const newPopupTfvList = popupTfvList.map(tfv => ((tfv.tfvId === tfvId && tfv.chartType === chartType) ?
      { ...tfv, status } : tfv));
    setPopupTfvList(newPopupTfvList);
  };

  const expandPopupTfv = (tfvId, chartType) => {
    const status = TrafficPopupStatus.OPEN;
    setPopupTfvList(
      popupTfvList.map(tfv => ((tfv.tfvId === tfvId && tfv.chartType === chartType) ?
        { ...tfv, status, position: { ...tfv.position, z: 999 } } : tfv)));
  };

  const collapseAll = () => {
    const status = TrafficPopupStatus.COLLAPSED;
    const newPopupTfvList = popupTfvList.map(tfv => (tfv.status !== status ?
      { ...tfv, status } : tfv));
    setPopupTfvList(newPopupTfvList);
  };

  const expandAll = () => {
    const status = TrafficPopupStatus.OPEN;
    const newPopupTfvList = popupTfvList.map(tfv => (tfv.status !== status ?
      { ...tfv, status } : tfv));
    setPopupTfvList(newPopupTfvList);
  };

  const closeAll = () => {
    setPopupTfvList([]);
    setPopupTfvListPositions([]);
  };

  const updatePosition = (tfvId, chartType, position) => {
    const listSize = popupTfvListPositions.length;
    const pos = { x: position.x, y: position.y, z: (baseZindex + listSize) };
    setPopupTfvListPositions(
      popupTfvListPositions.map(tfv => ((tfv.tfvId === tfvId && tfv.chartType === chartType) ?
        { ...tfv, position: pos } : { ...tfv, position: { ...tfv.position, z: (tfv.position.z - 1) } })));
  };

  const retrievePosition = (tfvId, chartType) => {
    const popup = popupTfvListPositions.filter(tfv => ((tfv.tfvId === tfvId && tfv.chartType === chartType)));
    return popup.length === 0 ? { x: 0, y: 0, z: baseZindex + popupTfvListPositions.length + 1 } : popup[0].position;
  };

  return {
    occupancyActive,
    setOccupancyActive,
    entryActive,
    setEntryActive,
    trafficLoadViewChanged,
    popupTfvList,
    setPopupTfvList,
    popupTfvListChanged,
    addPopupTfv,
    removePopupTfv,
    collapsePopupTfv,
    expandPopupTfv,
    collapseAll,
    expandAll,
    closeAll,
    updatePosition,
    retrievePosition,
    popupTfvListPositions,
    setPopupTfvListPositionsState,
    positionsUpdated,
    setPositionUpdated,
  };
};

export function TrafficLoadProvider({ children }) {
  const context = useTrafficLoadDefaultContext();
  return (
    <TrafficLoadContext.Provider value={context}>
      {children}
    </TrafficLoadContext.Provider>
  );
}

TrafficLoadProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default TrafficLoadContext;
export const useTrafficLoadContext = () => useContext(TrafficLoadContext);
