import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import leaflet from 'leaflet';
import 'leaflet-draw';

const MapContext = createContext();

export const MapLayersPopUps = {
  UDA_NFA_HOTSPOTS: 'uda_nfa_hotspots',
  UDA_NFA_DRAWER: 'uda_nfa_drawer',
  CONVECTION: 'convection',
  TURBULENCE: 'turbulence',
  TRAFFIC_FLOW: 'traffic_flow',
};

const createDrawers = (polygon, circle) => ({ polygon, circle });

export const useMapDefaultContext = () => {
  const map = useRef();

  const [drawers, setDrawers] = useState(createDrawers());

  const [showMapLayersPopUp, setShowMapLayersPopUp] = useState(undefined);
  const [showNFA, setShowNFA] = useState(false);
  const [showUDA, setShowUDA] = useState(false);
  const [udaForm, setUdaForm] = useState(false);
  const [notamUdaForm, setNotamUdaForm] = useState(false);
  const [isCreateCircleActive, setIsCreateCircleActive] = useState(false);
  const [isCreateFreeShapeActive, setIsCreateFreeShapeActive] = useState(false);
  const [isCreateNotamShapeActive, setIsCreateNotamShapeActive] = useState(false);

  useEffect(() => {
    if (map.current) {
      setDrawers(createDrawers(
        new leaflet.Draw.Polygon(map.current.leafletElement),
        new leaflet.Draw.Circle(map.current.leafletElement),
      ));
    } else {
      setDrawers(createDrawers());
    }
  }, [map.current]);

  return {
    drawers,
    map,
    showMapLayersPopUp,
    setShowMapLayersPopUp,
    showNFA,
    setShowNFA,
    showUDA,
    setShowUDA,
    udaForm,
    setUdaForm,
    notamUdaForm,
    setNotamUdaForm,
    isCreateCircleActive,
    setIsCreateCircleActive,
    isCreateFreeShapeActive,
    setIsCreateFreeShapeActive,
    isCreateNotamShapeActive,
    setIsCreateNotamShapeActive,
  };
};

export function MapProvider({ children }) {
  const context = useMapDefaultContext();
  return (
    <MapContext.Provider value={context}>
      {children}
    </MapContext.Provider>
  );
}

MapProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default MapContext;
export const useMapContext = () => useContext(MapContext);
