import React, { useEffect, useState, createContext, useContext } from 'react';
import { icons } from '@atfm/atfm-material-ui';
import PropTypes from 'prop-types';

const AlertContext = createContext();

export const AlertTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const useAlertDefaultContext = () => {
  const [showSnackBarTimeout, setShowSnackBarTimeout] = useState(120000);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState(undefined);
  const [errorMessageSnackBar, setErrorMessageSnackBar] = useState(undefined);
  const [snackBarIcon, setSnackBarIcon] = useState(undefined);

  useEffect(() => {
    switch (snackBarSeverity) {
      case AlertTypes.SUCCESS:
        setSnackBarIcon(<icons.CheckCircle />);
        break;
      case AlertTypes.INFO:
        setSnackBarIcon(<icons.Info />);
        break;
      case AlertTypes.WARNING:
        setSnackBarIcon(<icons.Warning />);
        break;
      case AlertTypes.ERROR:
        setSnackBarIcon(<icons.Error />);
        break;
      default:
        setSnackBarIcon(<icons.Help />);
        break;
    }
  }, [showSnackBar, snackBarSeverity]);

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  return {
    handleCloseSnackBar,
    showSnackBarTimeout,
    setShowSnackBarTimeout,
    showSnackBar,
    setShowSnackBar,
    snackBarSeverity,
    setSnackBarSeverity,
    errorMessageSnackBar,
    setErrorMessageSnackBar,
    snackBarIcon,
    setSnackBarIcon,
  };
};

export function AlertProvider({ children }) {
  const context = useAlertDefaultContext();
  return (
    <AlertContext.Provider value={context}>
      {children}
    </AlertContext.Provider>
  );
}

AlertProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default AlertContext;
export const useAlertContext = () => useContext(AlertContext);
