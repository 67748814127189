import { StorageHelper } from '@atfm/utils';
import callApi from '../api/services';

export const getUserAirports = () => {
  const username = StorageHelper.retrieveItem('username');
  return callApi(`/v1/user/${username}`, { basePath: window.env.SEQUENCER_API_BASE_URL });
};

// one request for arrival and one request for departure are needed because the payload response is different
export const getFlightScheduledTime = (airport, gufi, flow) => {
  const icao = airport;
  return callApi(`/v2/airport-sequence/${icao}/flight/${gufi}/${flow}`, { basePath: window.env.SEQUENCER_API_BASE_URL });
};
