import moment from 'moment';

import { ErrorHandler, CustomError } from '@atfm/utils';

import { Authentication, CriticalServices, MattermostPaths, avoidReplayPaths } from '../../constants';

export const replay = {};

export const interceptors = {
  headers: [],
  kc: {},
};

const tokenMinValiditySeconds = +window.env.OIDC_TOKEN_MIN_VALIDITY_MINS * 60;

export const setReplayParams = ({ delta }) => {
  replay.delta = delta;
};
export const clearReplayParams = () => {
  delete replay.delta;
};

export const mapApiService = {};
export const buildMapApiService = (api, service, status) => {
  mapApiService[api] = {
    ...mapApiService[api],
  };
  mapApiService[api][service] = { status };
};
export const clearMapApiService = () => {
  Object.keys(mapApiService).forEach(key => delete mapApiService[key]);
};

const basePath = options => (options.basePath ? options.basePath : window.env.API_BASE_URL);

const getApiPath = (path, options) => {
  let resultPath = basePath(options) + path;

  if (Object.keys(replay).length === 0) {
    return resultPath;
  }

  if (resultPath.includes('?')) {
    resultPath += '&';
  } else {
    resultPath += '?';
  }

  const toAvoidReplay = avoidReplayPaths.find(item => item === path);

  if (toAvoidReplay) {
    return resultPath;
  }

  const { delta } = replay;
  const replayTime = moment().valueOf() - delta;
  resultPath += `replay=${replayTime}&replay_mode=v1`;
  return resultPath;
};

const getServiceFromPathAndUrl = (path, url) => {
  let service;
  if (url.includes(MattermostPaths.CHAT)) {
    service = url.split('/').pop();
  } else if (url.includes(window.env.API_BASE_URL)) {
    if (path.includes('?')) {
      // eslint-disable-next-line prefer-destructuring
      service = path.substring(0, path.lastIndexOf('?')).split('/')[3];
    } else if (path.includes('api/')) {
      // eslint-disable-next-line prefer-destructuring
      service = path.split('/')[3];
    } else {
      // eslint-disable-next-line prefer-destructuring
      service = path.split('/')[2];
    }
  }
  return service;
};

const handleAppStatus = path => (response) => {
  let api;

  const { url, status } = response;
  const service = getServiceFromPathAndUrl(path, url);

  if (url.includes(MattermostPaths.CHAT)) {
    api = 'cdm';
  } else if (url.includes(window.env.API_BASE_URL)) {
    // eslint-disable-next-line prefer-destructuring
    api = url.replace(window.env.API_BASE_URL, ' ').split('/')[1];
  }

  if (api && service) {
    buildMapApiService(api, service, status);
  }

  return response;
};

const isServiceCritical = service => CriticalServices.includes(service);

const handleErrors = path => (response) => {
  const { url, status, statusText, ok } = response;
  const service = getServiceFromPathAndUrl(path, url);

  // Disconnect user if Token issue on a critical service call
  if (window && window.location && (status === 401 || status === 403) && isServiceCritical(service)) {
    window.location.assign(Authentication.LOGIN_REDIRECT);
  }

  if (!ok) {
    const formattedError = { status, statusText, url };
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json().then((r) => {
        formattedError.message = r.message;
        throw new CustomError({ message: JSON.stringify(formattedError), body: r });
      });
    }
    throw new CustomError({ message: JSON.stringify(formattedError) });
  }
  return response;
};

const buildHeaders = async (initialHeaders = {}) => {
  await interceptors.kc.updateToken(tokenMinValiditySeconds);

  return interceptors.headers.reduce(async (previous, interceptor) => {
    const headers = await previous;

    return interceptor(headers);
  }, Promise.resolve(initialHeaders));
};

const callApi = async (path, options = {}) => {
  const body = options.body ? JSON.stringify(options.body) : null;

  const headers = await buildHeaders({
    'Content-Type': (options.headers || {})['Content-Type'] || 'application/json',
    Accept: 'application/json',
    ...(options.headers || {}),
  });
  if (!body) {
    delete headers['Content-Type'];
  }

  const apiPath = getApiPath(path, options);

  const init = {
    ...options,
    body,
    headers,
  };

  return fetch(apiPath, init)
    .then(handleAppStatus(path))
    .then(handleErrors(path))
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        contentType &&
        (contentType.indexOf('application/json') !== -1 || contentType.indexOf('application/geo+json') !== -1)
      ) {
        return response.json();
      }
      return response;
    })
    .catch((err) => {
      ErrorHandler.logError(err);
      throw err;
    });
};

export default callApi;
