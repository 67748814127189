import callApi from '../api/services';
import { WeathersPaths } from '../../constants';

const queryRdtData = (atTime, severity) => {
  const timeQuery = atTime ? `&from=${atTime}&to=${atTime}` : '';
  const severityQuery = severity ? `&s=${severity}` : '';
  return callApi(
    `${WeathersPaths.RDT}?format=geojson${timeQuery}${severityQuery}`,
    { basePath: window.env.WEATHER_API_BASE_URL },
  );
};

export default queryRdtData;
