import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAlertContext } from './AlertContext';
import { useAppContext } from './AppContext';
import { useTmiContext } from './TmiContext';
import { useTrafficLoadContext } from './TrafficLoadContext';
import { useUserContext } from './UserContext';

const GlobalContext = createContext();

const useSuperContext = () => ({
  ...useAlertContext(),
  ...useAppContext(),
  ...useTmiContext(),
  ...useTrafficLoadContext(),
  ...useUserContext(),
});

export function GlobalProvider({ children }) {
  const context = useSuperContext();
  return (
    <GlobalContext.Provider value={context}>
      {children}
    </GlobalContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default GlobalContext;
export const useGlobalContext = () => useContext(GlobalContext);
