/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { ErrorHandler, JwtTokenUtils, usePromise } from '@atfm/utils';
import Keycloak from 'keycloak-js';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { globalAuthConfig, initAuthConfig } from '../../authentication/config';
import { interceptors } from '../../store/api/services';
import { setAuthenticationData } from '../../store/authentication/actions';

const tokenMinValiditySeconds = +window.env.OIDC_TOKEN_MIN_VALIDITY_MINS * 60;

const useAuthDefaultContext = () => {
  const dispatch = useDispatch();

  const { current: keycloakClient } = useRef(new Keycloak(globalAuthConfig));
  const { current: initialization } = usePromise();

  /**
   * Configure and initialize keycloak
   * @param {Keycloak} kc the keycloak instance
   * @returns a boolean representing the status of initialization
   */
  const initialize = async (kc) => {
    try {
      // Configure keycloak callback event
      // kc.onReady = (e) => console.log(' onReady');
      // kc.onAuthSuccess = (e) => console.log(' onAuthSuccess');
      // kc.onAuthError = (e) => console.log(' onAuthError');
      // kc.onAuthLogout = (e) => console.log(' onAuthLogout');
      kc.onAuthError = (e) => {
        console.error('Error while attempting to authenticate: ', e);
      };
      kc.onAuthRefreshError = (e) => {
        console.error('Error while attempting to refresh: ', e);
      };
      const authenticated = await kc.init(initAuthConfig);
      initialization.resolve(authenticated);
      return authenticated;
    } catch (e) {
      ErrorHandler.logError(e);
      initialization.reject(e);
      throw e;
    }
  };
  /**
   * Process init and refresh headers on each call then dispatch auth data
   */
  const doInitAndRefreshToken = async () => {
    if (!keycloakClient.authenticated) {
      await initialize(keycloakClient);
    }

    interceptors.kc = keycloakClient;

    interceptors.headers.push(async (headers) => {
      await initialization.promise;

      // If the Authorization header is already present, return headers as-is
      if (headers.Authorization) {
        return headers;
      }

      try {
        await keycloakClient.updateToken(tokenMinValiditySeconds);
      } catch (e) {
        ErrorHandler.logError(e);
        keycloakClient.clearToken();
      }

      return {
        ...headers,
        Authorization: `Bearer ${keycloakClient.token}`,
      };
    });

    const parsedToken = keycloakClient.tokenParsed;
    const accessToken = keycloakClient.token;

    const authData = {
      ...JwtTokenUtils.getAuthenticationDataFromParsedToken(parsedToken),
      ...JwtTokenUtils.getCdmDataFromParsedToken(parsedToken, window.env.EXECUTION_ENV),
    };

    await dispatch(setAuthenticationData({ accessToken, parsedToken, ...authData }));
  };

  useEffect(() => {
    doInitAndRefreshToken();
  }, []);

  return {
    initialization: initialization.promise,
    async login() {
      await initialization.promise;
      return keycloakClient.login();
    },
    async logout() {
      await initialization.promise;
      return keycloakClient.logout();
    },
  };
};

const AuthContext = createContext();
export function AuthProvider({ children }) {
  const context = useAuthDefaultContext();
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}
AuthProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default AuthContext;
export const useAuthContext = () => useContext(AuthContext);
